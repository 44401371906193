<template>
	<a :class="type" rel="noopener noreferrer" @click.prevent="openBookingEngine({ ...$props })">
		<slot />
	</a>
</template>

<script setup>
import BookingEngine from '@becurious/roomraccoon';
let bookingengine = {};

defineProps({
	language: { type: String, default: '' },
	arrival: { type: String, default: '' },
	departure: { type: String, default: '' },
	pax: { type: String, default: '' },
	packageid: { type: String, default: '' },
	roomid: { type: String, default: '' },
	type: { type: String, default: '' },
});

const { locale } = useI18n();

const openBookingEngine = (options) => {
	if (!options.language) {
		options = { ...options, language: locale.value };
	}

	window.open(bookingengine.getUrl(options));
};

onMounted(() => {
	bookingengine = new BookingEngine({
		hotelID: 'be41',
	});
});
</script>

<style lang="scss" scoped>
a {
	color: #fff;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}
</style>
